@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

body {
  letter-spacing: 0.025em;
  @apply text-black;
  @apply font-light;
  -webkit-font-smoothing: antialiased;
}

.ReactModal__Overlay {
  animation: fadein 1s ease-out;
}

.css .hljs {
  line-height: 1.9;
}

.hljs {
  border-radius: 6px;
}

.usingForGameEditor {
  padding: 0 !important;
  border-radius: 0 !important;
}

ul,
ol {
  list-style: initial;
  padding: 0 0 0 40px;
}

ol {
  list-style: decimal;
}

.spinner circle {
  opacity: 25%;
}

.spinner path {
  opacity: 75%;
}

.react-toast-notifications__toast__icon-wrapper {
  display: none;
}

/* animation */

.flip {
  transform: scale(-1, 1);
}

.pulse {
  animation-name: pulse;
}

.flow {
  animation: flow 3s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.15, 1.15, 1.15);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes flow {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* landing */

.land__hero__image {
  width: 32rem;
  height: 32rem;
  position: relative;
}

.land__hero__image img {
  object-fit: cover;
}

.border-purple-50 {
  border-color: #faf5ff;
}
.border-purple-100 {
  border-color: #f3e8ff;
}
.border-purple-200 {
  border-color: #e9d5ff;
}
.border-purple-300 {
  border-color: #d8b4fe;
}
.border-purple-400 {
  border-color: #c084fc;
}
.border-purple-500 {
  border-color: #a855f7;
}
.border-purple-600 {
  border-color: #9333ea;
}
.border-purple-700 {
  border-color: #7e22ce;
}
.border-purple-800 {
  border-color: #6b21a8;
}
.border-purple-900 {
  border-color: #581c87;
}

.border-red-50 {
  border-color: rgb(254, 242, 242);
}
.border-red-100 {
  border-color: rgb(254, 226, 226);
}
.border-red-200 {
  border-color: rgb(254, 202, 202);
}
.border-red-300 {
  border-color: rgb(252, 165, 165);
}
.border-red-400 {
  border-color: rgb(248, 113, 113);
}
.border-red-500 {
  border-color: rgb(239, 68, 68);
}
.border-red-600 {
  border-color: rgb(220, 38, 38);
}
.border-red-700 {
  border-color: rgb(185, 28, 28);
}
.border-red-800 {
  border-color: rgb(153, 27, 27);
}
.border-red-900 {
  border-color: rgb(127, 29, 29);
}

@media (min-width: 640px) {
  .sm\:w-96 {
    width: 24rem /* 384px */;
  }
}

.scrollSafari {
  -webkit-overflow-scrolling: touch;
}

@screen lg {
  .scrollSafari {
    -webkit-overflow-scrolling: auto;
  }
}
